/// <reference lib="WebWorker" />
declare const self: ServiceWorkerGlobalScope;
// export empty type because of tsc --isolatedModules flag
export type { };
//importScripts('https://cdn.jsdelivr.net/npm/idb-keyval@3/dist/idb-keyval-iife.min.js');
let UA = '';
const VERSION = '1.10';
const CACHE_SET = {
    default: `practice-cache-v${VERSION}`,
    image: `practice-image-cache-v1`,
    react: `practice-react-cache-v${VERSION}`,
    reactTest: `practice-react-test-v${VERSION}`,
    jsonData: 'practice-json-cache'
} as const;

type CacheName = typeof CACHE_SET[keyof typeof CACHE_SET];
//idbKeyval.set('hello', {start: new Date(), SQLID: 29923});
self.addEventListener('install', function (event) {
    const urlsToCache = [
        '/dist/main-v1-10.js',
        '/dist/practice-v1-3.css',
        '/practice'
    ];
    // Perform install steps
    self.skipWaiting();
    event.waitUntil(
        addInitialItems(urlsToCache)
    );
});
self.addEventListener('activate', function (event) {
    event.waitUntil(
        Promise.all([
            deleteOldCaches(),
            deleteKeys(CACHE_SET.react, [/v1-7/])
        ]).then(([oldCaches]) => {
            logEvent(`activated ${VERSION} ${oldCaches.length ? "deleted " + oldCaches.join(',') : ""} ${navigator.userAgent}`);
            return 0;
        })
    );
});
self.addEventListener('fetch', function (event) {
    const itemURL = event.request.url;
    if (/ftdata/.test(itemURL) || /\/data\//.test(itemURL))
        event.respondWith(
            fetchAndCache(event.request)
                .catch(async function (e) {
                    const response = await caches.match(event.request);
                    // Cache hit - return response
                    if (response) {
                        console.log('returning cache item for ' + itemURL);
                        return response;
                    }
                    const init = { "status": 500, "statusText": "offline" };
                    return new Response("", init);
                })
        )
    else {
        const queryString = new URL(itemURL).searchParams.has('beta') ? '?beta=1' : '';
        const useHTMLStub: string | false = event.request.method === "GET" && [/login/, /cancel/, /\/practice\/[a-z\-]+\/?[^.]*$/].some(r => r.test(itemURL)) && `/practice${queryString}`;
        event.respondWith(
            caches.match(useHTMLStub || event.request)
                .then(function (response) {
                    // Cache hit - return response
                    if (response) {
                        return response;
                    }
                    return fetchAndCache(event.request)
                        .catch(function (e) {
                            const init = { "status": 500, "statusText": "offline" };
                            return new Response("", init);
                        });
                })
        );
    }
});
self.addEventListener("message", function (event) {
    // update the UA variable with the event.data, which is the browser's UserAgent
    UA = event.data;
    logEvent(`Using SW ${VERSION}: ${UA}`);
    //console.log('ServiceWorker now has UA variable defined as ', UA);
}, false);
const addInitialItems = async (urlsToCache: string[]) => {
    const cacheMap: Partial<Record<CacheName, Promise<Cache>>> = {};
    //const renCache = renameCache('my-site-cache-v1.6', 'practice-cache-v1.6');
    //cacheMap[CACHE_SET.default] = renCache;
    const addUrlArr = urlsToCache.map(async url => {
        const myCacheName = selectCache(url);
        const myCache = cacheMap[myCacheName] || (cacheMap[myCacheName] = caches.open(myCacheName));
        return (await myCache).add(url);
    });
    return Promise.all(addUrlArr);
}
const fetchAndCache = async (req: Request) => {
    const resp = await fetch(req);
    if (!resp ||
        resp.status !== 200 ||
        !req.url.startsWith('http') ||
        resp.type !== 'basic' ||
        req.method === 'POST' ||
        excludeFromCache(req.url)) {
        return resp;
    }
    const responseToCache = resp.clone();
    const cache = await caches.open(selectCache(req.url));
    await cache.put(req, responseToCache);
    return resp;
}

const excludeList = [/sw.js/, /fb.json/, /status.json/, /schedulefix/, /.aspx/, /payment/, /cancel/, /login/, /\/practice\/([^f][r]|[^f][^r]|f[^r])[a-z\-]+\/?[^.]*$/, /html/]
function excludeFromCache(itemURL: string): boolean {
    return excludeList.some(regex => regex.test(itemURL));
}
function selectCache(itemURL: string): CacheName {
    if (/.jpg/.test(itemURL) ||
        /.png/.test(itemURL) ||
        /.ico/.test(itemURL) ||
        /images\//.test(itemURL))
        return CACHE_SET.image;
    else if (/disttest/.test(itemURL))
        return CACHE_SET.reactTest;
    else if (/dist\/[0-9A-Za-z\-]+.(js|css)/.test(itemURL))
        return CACHE_SET.react;
    else
        return CACHE_SET.default;
}
async function deleteKeys(cache: CacheName, toRemove: RegExp[]) {
    const myCache = await caches.open(cache);
    const cacheFiles = await myCache.matchAll();
    const filesToRemove = cacheFiles.filter(resp => toRemove.some(reg => reg.test(resp.url)));
    const delArray = filesToRemove.map(resp => myCache.delete(resp.url));
    return Promise.all(delArray);
}
const deleteOldCaches = async () => {
    const cacheAllowlist = Object.values(CACHE_SET);
    const oldCacheNames = (await caches.keys()).filter(c => !cacheAllowlist.includes(c as CacheName));
    await Promise.all(oldCacheNames.map(c => caches.delete(c)));
    return oldCacheNames;
};
const renameCache = async (curName: string, newName: CacheName, keepOld?: boolean) => {
    const newCache = caches.open(newName);
    const cacheFound = (await caches.keys()).filter(c => c === curName);
    if (cacheFound.length) {
        const curCache = await caches.open(curName);
        const curResp = await curCache.matchAll();
        const putArr = curResp.map(async resp => (await newCache).put(resp.url, resp));
        await Promise.all(putArr);
        if (!keepOld) caches.delete(curName);
    }
    return newCache;
}
let logsToSend: string[] = [];
let logTimer = 0;
const logEvent = async (logText: string, SessionId?: string) => {
    clearTimeout(logTimer);
    logsToSend.push(logText);
    SessionId = SessionId || CACHE_SET.default;
    if (logsToSend.length > 5)
        sendAllMessages();
    else
        logTimer = setTimeout(sendAllMessages, 1500) as unknown as number;
    function sendAllMessages() {
        if (logsToSend.length) {
            const logAccum = logsToSend.join(',');
            logsToSend = [];
            return fetch("https://api.candell.org/logEvent",
                {
                    mode: 'cors',
                    method: "POST",
                    headers: {
                        'Accept': 'application/json',
                    },

                    body: JSON.stringify({ logText: logAccum, SessionId })
                });
        }
    }
}
